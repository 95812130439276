import {Routes, Route, Navigate} from 'react-router-dom'
import {useState, useEffect} from 'react'
import Login from './Login.js'
import CustomerHome from './customer/CustomerHome.js'
import useLogin from '../hooks/useLogin.js'
import Cookies from 'js-cookie'


function Customer(){

  const {sendOTP, login, updateProfile, logout, getLoginData} = useLogin()

  const [auth, setAuth] = useState(false)

  const [profile, setProfile] = useState(JSON.parse(sessionStorage.getItem('profile')) || {})

  // if (!window.navigator.onLine) {
  //   alert("You are offline")
  // }

  useEffect(() => {
    const user = Cookies.get('auth_token')
    if (user) {
      setAuth(true)
    }
  }, [])

  useEffect(() => {
    if (!auth) {
      setProfile({})
    }
  }, [auth])

  return(
    <Routes>


      <Route path="/login" element = {!auth ? <Login {...{sendOTP, login, setAuth, setProfile, getLoginData}} /> :
        <Navigate to ="/web/customer/home/" replace /> } />

      <Route path="/home/*" element = {auth ? <CustomerHome {...{profile, setProfile, setAuth, updateProfile, logout}} /> :
        <Navigate to ="/web/customer/login" replace />} />

      <Route path = "*" element = { <Navigate to ="/web/customer/login" replace />} />

    </Routes>
  )
}

export default Customer
