import {Link} from 'react-router-dom'
import Toast from '../Toast.js'
import {useState, useEffect} from 'react'
import useWarranty from '../../hooks/useWarranty.js'
import WarrantyDetails from './WarrantyDetails.js'
import ClaimForm from './ClaimForm.js'
import ClaimDetails from './ClaimDetails.js'
//import { useLocation } from "react-router-dom";

function WarrantyList({product, profile}){

  // use warrantyList function to get warranties
  const {warrantyList} = useWarranty()

  // manage state of all warranties
  const [warranties, setWarranties] = useState([])

  //manage state of single warranty
  const [warrantyItem, setWarrantyItem] = useState('')

  //manage state of active view
  const [view, setView] = useState('warrantyList')

  //manage state of errors, set error when get error in api request
  const [error, setError] = useState({status: false, message: ''})

  //show loader while fetching list
  const [loader, setLoder] = useState(true)

  //const location = useLocation();

  //fetch warranties on initial render
  useEffect(() => {
    warrantyList(setWarranties, setError)
  }, [])

  //check if warranty exitst
  useEffect(() => {

    setLoder(false)

    if (warranties.length < 1 || !warranties) {
      setError({status: false, message: 'No Warranty is available'})
    }
  }, [warranties])

  //change view on warranty details click
  const handleClickDetails = (warranty) => {
    setWarrantyItem(warranty)
    setView('warrantyDetails')
  }

  //change view on claim click
  const handleClickClaim = (warranty) => {
    setWarrantyItem(warranty)
    setView('warrantyClaim')
  }

  const handleClickClaimDetails =(warranty) =>{
    setWarrantyItem(warranty)
    setView('claimDetails')
  }


  // single warranty card component
  const WarrantyCard = ({warranty, onClickDetails, onClickClaim, onClickClaimDetails}) => {
    //console.log(warranty)
    let date = new Date()
    let warrantyExp = new Date(warranty.warrenty_to)
    let isExpired = date.getTime() > warrantyExp.getTime() ? true : false

    return(
      <div className="boxwrty mb-5">
          <div className="headbtn">
              <div className="claimbx clearfix ">
                  <Link to ="" className="claimbx_btn rdspd pull-left" onClick = {onClickDetails}>
                    <img src="/images/view-clm.svg" alt="Claim Now" />View Details
                  </Link>

                  {warranty.claim_id ?
                    <Link to = "" className="claimbx_btn rdspd btnbd pull-right" onClick = {onClickClaimDetails}>
                       <img src="/images/claim.svg" alt="Claim Now" />Claim Details
                    </Link> :

                    <Link to = "" className="claimbx_btn rdspd btnbd pull-right" onClick = {onClickClaim}>
                       <img src="/images/claim.svg" alt="Claim Now" />Claim Now
                    </Link>}


              </div>
          </div>

          <div className="imgctn">
              <div className="imgpwbx">
                  <img src={warranty ? warranty.brand_logo : ""} alt="" />
              </div>

              <div className="cntpwt">
                  <h5>{warranty ? warranty.product_name : ''}</h5>
                  <span>Batch Code&nbsp;&nbsp;:&nbsp;&nbsp;{warranty ? warranty.batch_code : ''}</span>
                   <span>Purchased By&nbsp;&nbsp;:&nbsp;&nbsp;{warranty ? warranty.name : ''}</span>
                  <span>Purchased On&nbsp;&nbsp;:&nbsp;&nbsp;{warranty ? warranty.warrenty_from : ''}</span>
                  <span>Warranty Till&nbsp;&nbsp;:&nbsp;&nbsp;{warranty ? warranty.warrenty_to : ''}</span>
              </div>
          </div>

          <div className="wrttop text-center">
              <span>Warranty status&nbsp;&nbsp;:&nbsp;&nbsp;<span>{isExpired ? "Expired" : "Activated"}</span></span>
          </div>
      </div>
    )
  }

  // default warranty list page
  if (view === 'warrantyList') {
    return(<>
      {error.status ? <Toast message ={error.message} hideToast = {() => setError({...error, status: false})} /> : ''}
      {loader &&
        <div className = "sm_loader_ctr">
          <div className ="sm_loader"></div>
        </div>
      }
      <div className="back_heading pdga">
         <Link to = "/web/customer/home" className="container">
           <img src="/images/share-arrow.svg" className="mr-2" alt="Product Warranty List" />Product Warranty List
         </Link>

      </div>

      <div className="container">
        {warranties ? warranties.map((warranty, i) => {
          return( <WarrantyCard warranty = {warranty} key={i}
            onClickDetails ={() => handleClickDetails(warranty)}
            onClickClaim ={() => handleClickClaim(warranty)}
            onClickClaimDetails ={() => handleClickClaimDetails(warranty)}  />)
        }) : ''}
      </div>
    </>)
  }

  //change view to warranty details
  if (view === 'warrantyDetails') {
    return(<WarrantyDetails {...{profile, product, warrantyItem, view, setView}} />)
  }

  //change view to warranty claim form
  if (view === 'warrantyClaim') {
    return(<ClaimForm {...{profile, warrantyItem, setView}} />)
  }

  //change view to warranty claim form
  if (view === 'claimDetails') {
    return(<ClaimDetails {...{warrantyItem, setView}} />)
  }

}

export default WarrantyList
