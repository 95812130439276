import {useState} from 'react'
import useLogin from './useLogin'
import Cookies from 'js-cookie'
import axios from 'axios'
import Compressor from 'compressorjs';

function useGM(){

  const baseUrl = "https://genuinemark.org/mobileApi/admin/api/v2/test/"

  const [user, setUser] = useState({
	  token: null,
	  user: null,
  })

  function sendOtp(data){
    //mobile no
    let request = postJson(baseUrl+"sendOtp", {data: data})
    return request

  }

  function singleQrcodeScan(data) {
		data.platform = 'WEB'
		// qrcode, auth_token, save_only
		let options = {
			data: data
		}
		let request = postJson(baseUrl+"singleQrcodeScan", options)
		return request
	}

  function customerLogin(data){
    // mobileNo, otp, latitude, longitude
    let request = postJson(baseUrl+"customerLogin", {data: data})
    return request
  }

  function customerUpdateProfile(data) {
		// name, email, title, mobile, auth_token
		let request = postJson(baseUrl+"customerUpdateProfile", {
			data: data
		});
		return request
	}

  function activateWarranty(fd, setUploadProgress) {
    fd.user_type = Cookies.get("user_type")
		return postForm(baseUrl+"activateWarranty", {
			data: fd
		}, setUploadProgress);

	}

  function warrantyHistory() {
		let request = postJson(baseUrl+"warrantyHistory_v2", {});
		return request
	}

  function createWarrantyClaim(fd, setUploadProgress) {
    fd.user_type = Cookies.get("user_type")
    let formData = new FormData();
    for (var i = 0; i < fd.image.length; i++) {
      formData.append("image[]", fd.image[i]);
    }
    delete fd.image;
		return postForm(baseUrl+"warrantyClaim/create", {
			data: fd,
      formData : formData
		}, setUploadProgress);
	}

  //https://genuinemark.org/mobileApi/admin/api/v2/test/warrantyClaim/getList?warranty=24
  function getWarrantyclaim(warranty_id) {
		let request = fetch(baseUrl+'warrantyClaim/getList?warranty='+warranty_id, {
      method: 'POST',
      headers:{
        "Content-type": "application/json",
        "Token" : Cookies ? Cookies.get('auth_token') : '',
      },
      body: JSON.stringify({user_type : Cookies.get('user_type')})
    })
		return request
	}

  function getClaimChats(data) {
		let options = { data: data }
		let request = postJson(baseUrl+"warrantyClaim/getClaimChats", options)
		return request
	}

	function addClaimChat(fd, setUploadProgress) {
		return postForm(baseUrl+"warrantyClaim/addChat", {
			data: fd
		}, setUploadProgress)
		//return request
	}

  function postJson(url, obj){

    let options = {
      method: 'POST',
      headers:{
        "Content-type": "application/json",
        "Token" : Cookies ? Cookies.get('auth_token') : ''
      },
      body : {}
    }

    if (!obj.hasOwnProperty('data')) {
	  	obj.data = {};
	  }

    obj.data.user_type = Cookies ? Cookies.get('user_type') : null
    options.body = JSON.stringify(obj.data)
    //console.log(options.data)
    let response = fetch(url, options)
    return response
  }

  function postForm(url, obj, setUploadProgress){

    //check ig it is FileList (selected multiple files)
    // if (obj.data.image) {
    //   let files = obj.data.image;
    //   let compressedFiles = [];
    //   files.forEach((file, i) => {
    //     //compressFile(file, setUploadProgress)
    //   });
    // //claim chat media
    // }else if(obj.data.media){
    //   let file = obj.data.media;
    //   compressFile(file, setUploadProgress)
    // //activate warranty invoice_image
    // }else{
    //   let file = obj.data.invoice_image;
    //   compressFile(file, setUploadProgress)
    // }
    //
    // function compressFile(file, setUploadProgress){
    //   new Compressor(file, {
    //      quality: 0.6,
    //      // The compression process is asynchronous,
    //      // which means you have to access the `result` in the `success` hook function.
    //       success(result) {
    //         obj.data.media = result
    //         console.log(obj.data.media)
    //      },
    //      error(err) {
    //        console.log(err.message);
    //      },
    //    })
    // }

    let fd = new FormData()

    if (obj.hasOwnProperty('formData')) {
      fd = obj.formData;
    }
    if (obj.hasOwnProperty('data')) {
      Object.entries(obj.data).map(([key, value]) => {
        fd.append(key, value)
      })
    }

    let options = {
      method: 'POST',
      headers:{
        // "Content-type": undefined,
        "Token" : Cookies ? Cookies.get('auth_token') : ''
      },
      body : fd
    }

    var response = axios.post(url, fd, {
      method: 'POST',
      headers:{
        // "Content-type": undefined,
        "Token" : Cookies ? Cookies.get('auth_token') : ''
      },
      onUploadProgress: uploadProgress =>{
        const progress = Math.round(uploadProgress.loaded / uploadProgress.total * 100)
        setUploadProgress(progress)
        //console.log(uploadProgress.loaded / uploadProgress.total * 100 + '%')
      }
    })
    return response
  }

 return {sendOtp, customerLogin, customerUpdateProfile, activateWarranty,
   warrantyHistory, singleQrcodeScan, createWarrantyClaim, getWarrantyclaim,
   getClaimChats, addClaimChat}

}

export default useGM
