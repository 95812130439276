import { Link, Navigate } from "react-router-dom";
import Toast from "../Toast.js";
import ProgressBar from "../ProgressBar.js";
import { useState, useEffect } from "react";
import useClaim from "../../hooks/useClaim.js";
import { Modal, Carousel } from "react-bootstrap";
import product1 from "../../assets/images/product1.jpeg";
import product2 from "../../assets/images/product2.jpeg";

function ClaimForm({ profile, warrantyItem, setView }) {
  const { saveClaimData } = useClaim();
  const [showModal, setShowModal] = useState(false);
  const product = [product1, product2];

  const handleOpenModal = (image) => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const [claimForm, setClaimForm] = useState({
    warranty_id: warrantyItem.id,
    vendor_id: warrantyItem.vendor_id,
    qrcode_id: warrantyItem.qrcode_id,
    description: "",
    image: "",
  });

  const [error, setError] = useState({ status: false, message: "" });
  const [popup, setPopup] = useState({ status: false, message: "" });
  const [backPage, setBackpage] = useState(false);
  const [loader, setLoader] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const handlechange = (e) => {
    setClaimForm({ ...claimForm, [e.target.name]: e.target.value });
  };

  const selectImages = (e) => {
    setClaimForm({ ...claimForm, image: e.target.files });
  };

  //console.log(claimForm.images)

  // if (!claimForm.images.length) {
  //    setError({...error,
  //      status: true,
  //      message: "Please upload invoice"
  //    })
  // }

  //else{
  //   let count = claimForm.images.length
  //   console.log(count)
  //   // if (count > 0) {
  //   //   for (var i = 0; i < count; i++) {
  //   //     let ext = claimForm.images[1].name.split('.').pop().toLowerCase()
  //   //     let allowed = ['png', 'jpg', 'jpeg', 'pdf']
  //   //     if (!allowed.includes(ext)) {
  //   //       setError({...error,
  //   //         status: true,
  //   //         message: "Only pdf, png, jpg and jpeg files are allowed"
  //   //       })
  //   //     }
  //   //   }
  //   // }
  // }

  const saveClaim = () => {
    //console.log(claimForm)
    setLoader(true);
    saveClaimData(claimForm, setError, setPopup, setUploadProgress, setLoader);
  };

  const hidePopup = () => {
    setPopup({ status: false });
    setBackpage(true);
  };

  useEffect(() => {
    if (popup.status === true || popup.status || error.status === true) {
      setLoader(false);
      setUploadProgress(0);
    }
  }, [error, popup, setLoader, setUploadProgress]);

  if (!backPage) {
    return (
      <>
        <div className="blc_bg pda_blc">
          {error.status ? (
            <Toast
              message={error.message}
              hideToast={() => setError({ ...error, status: false })}
            />
          ) : (
            ""
          )}
          {popup.status && (
            <div className="popup showpop">
              <div className="popbx">
                <span className="close_btn" onClick={() => hidePopup()}>
                  <img src="/images/close.svg" alt="" />
                </span>
                <div className="poptxt">
                  <img src="/images/userpop.svg" alt="" />
                  <h3>Dear {profile.name}.</h3>
                  <p>
                    Thank you for submitting your details.
                    <br />
                    We'll get back to you soon.
                  </p>
                </div>
              </div>
            </div>
          )}

          {loader && (
            <div className="popup showpop">
              <div className="popbx">
                <img src="/images/userpop.svg" alt="" />
                <h3>Uploading...</h3>
                <div className="mt-3 ">
                  <div className="upload_spin"></div>
                </div>
                <ProgressBar bgcolor={"#E00B0B"} completed={uploadProgress} />
              </div>
            </div>
          )}

          <div className="container">
            <div className="back_heading">
              <Link to="" onClick={() => setView("warrantyList")}>
                <img
                  src="/images/share-arrow.svg"
                  className="mr-2"
                  alt="Claim Warranty Form"
                />
                Claim Warranty Form
              </Link>
            </div>

            <div className="active_in">
              <div className="diseble_frm">
                <span>Product Name</span>
                <h6>{warrantyItem ? warrantyItem.product_name : ""}</h6>
              </div>
              <div className="diseble_frm ">
                <span>Batch Code</span>
                <h6>{warrantyItem ? warrantyItem.batch_code : ""}</h6>
              </div>

              <div className="diseble_frm ">
                <span>Name</span>
                <h6>{warrantyItem ? warrantyItem.name : ""}</h6>
              </div>

              <div className="diseble_frm ">
                <span>Mobile No</span>
                <h6>{profile ? profile.mobile : ""}</h6>
              </div>

              <div className="diseble_frm nonebr">
                <span>Email Id</span>
                <h6>{profile ? profile.email : ""}</h6>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="boxpd  fixed_btm">
            <div className="activeform">
              <div className="active_in">
                <div className="position-relative input_ds mt-5">
                  <textarea
                    placeholder="Enter Description"
                    cols="5"
                    rows="3"
                    className="inputbox "
                    name="description"
                    onChange={handlechange}
                    value={claimForm.description}
                  />
                </div>
                <label>
                  Please upload the correct product image *
                  <i
                    className="fa fa-info-circle"
                    onClick={() => handleOpenModal()}
                  ></i>
                </label>
                <div className="position-relative input_ds ">
                  <>
                    <div className="inputbox inht FileUpload_up">
                      <span>Image</span>
                      <input
                        type="file"
                        placeholder=""
                        onChange={selectImages}
                        multiple
                        accept="image/jpeg,image/png,image/jpg,.pdf"
                        style={{ left: "24px" }}
                      />
                    </div>
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="btn_fixed">
          <button
            type="submit"
            className="logbtn"
            id="popshow"
            onClick={saveClaim}
          >
            <img src="/images/send.svg" alt="Submit" className="mr-2" />
            Submit
          </button>
        </div>

        <Modal
          show={showModal}
          onHide={handleCloseModal}
          size="xl"
          centered
          dialogClassName="custom-modal"
        >
          <Modal.Header className="d-flex justify-content-between">
            <button type="button" className="close" onClick={handleCloseModal}>
              <span aria-hidden="true" className="larger-close-button">
                &times;
              </span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <Carousel
              prevLabel={null}
              nextLabel={null}
              indicators={false}
              variant="dark"
            >
              {product.map((image, index) => (
                <Carousel.Item key={index}>
                  <img
                    src={image}
                    className="img-size"
                    alt={`Slide ${index}`}
                  />
                </Carousel.Item>
              ))}
            </Carousel>
          </Modal.Body>
          <Modal.Footer>
            Please Upload product image in jpg,jpeg, png, or pdf format.
          </Modal.Footer>
        </Modal>

        <style>
          {`
    .custom-modal {
    max-width: 90%;
    margin: 0 auto;
    padding: 2rem;
    height: 50%;
  }

  .img-size {
    height: 300px; /* Adjust the desired height */
    width: auto;
    object-fit: contain;
    margin: auto;
    padding: auto;
    display: flex;
  }

  .gallery-image {
    max-height: 200px;
    width: auto;
    object-fit: cover;
  }

  .carousel-indicators li {
    background-color: #000; // Change this color to adjust the indicators' background color
    width: 10px; // Change this value to adjust the width of the indicators
    height: 2px; // Change this value to adjust the height of the indicators
  }

  .carousel-indicators .active {
    background-color: #fff; // Change this color to adjust the active indicator's background color
  }

  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    filter: invert(1);
  }

  .larger-close-button {
    font-size: 2rem; /* Adjust the desired font size for the close button */
  }
`}
        </style>
      </>
    );
  } else {
    return <Navigate to="/web/customer/home/" replace />;
  }
}

export default ClaimForm;
