import {useState, useEffect} from 'react'

function Banner({videoUri, companyLogo, images}){
  // const images = ["https://genuinemark.org/upload/brand_logo/Vega-Auto-vega-logo.png",
  //                "https://genuinemark.org/upload/-vega-logo.png",
  //                "https://genuinemark.org/upload/product/big/408-Vega Helmet Image.jpg"]

  let imgCount = images ? images.length : 0
  const [currentIndex, setCurrentIndex] = useState(0)

  useEffect(() => {
    const index =
         setInterval(() => setCurrentIndex(currentIndex < imgCount - 1 ? currentIndex + 1 : 0), 3000)
        return () => clearInterval(index)
  }, [currentIndex])

  return(
    <div className="banner">
        <div id="demo" className="carousel slide" data-ride="carousel">


            <ul className="carousel-indicators">
                {images ? images.map((image , i) => {
                  return(<li data-target="#demo" data-slide-to="0" className={i == currentIndex ? "active" : ''} key ={i}></li>)
                }) : ''}
            </ul>


            <div className="carousel-inner">
                {/* <span className="icong">
                    <img src="/images/genefied-logo-new.png" alt="" />
                </span> */}
                
                {videoUri ? <a href={videoUri} className="vto"><img src="/images/video-camera.png" alt="" />VIDEO</a> : ""}

                 {images ? images.map((image , i) => {
                   return(
                     <div className={i == currentIndex ? "carousel-item active" : "carousel-item"} key ={i}>
                         <div className="img_s">
                             <img src={image} alt="" />
                         </div>
                     </div>
                   )
                 }) : ''}

            </div>


        </div>
    </div>
  )
}

export default Banner
