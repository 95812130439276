import {Link} from 'react-router-dom'

function Profile({profile, setAuth, logout}){

  let profilePic = profile ? profile.profile_pic : ""

  return(<>
    <div className="blc_bg pda_blc">
                <div className="container">
                    <div className="back_heading">
                        <Link to = "/web/customer/home">
                           <img src="/images/share-arrow.svg" className="mr-2" alt="Profile Details" />Profile Details
                        </Link>

                    </div>
                    <div className="pdnow">
                        <div className="profile_menu clearfix">
                            <div className="profile_menu_img">
                                <div className="img_crl">
                                    <img src={profilePic ? profilePic : "/images/user_p.png"} alt="" />
                                </div>
                            </div>

                            <div className="profile_menu_text">
                                <span>Welcome!</span>
                                <h2>{profile ? profile.name : 'User'}</h2>

                            </div>
                        </div>

                    </div>
                </div>
    </div>

    <div className="boxpd fixed_btm mt-2">

                        <div className="container">

                            <div className="list_profile">
                                <ul className="clearfix">
                                    <li>
                                        <span><img src="/images/p-user.svg" alt="" /></span>
                                        <h5>{profile ? profile.name : ''}</h5>
                                    </li>

                                    <li>
                                        <span><img src="/images/p-email.svg" alt="" /></span>
                                        <h5>{profile ? profile.email : ''}</h5>
                                    </li>

                                    <li>
                                        <span><img src="/images/p-mobile.svg" alt="" /></span>
                                        <h5>{profile ? profile.mobile : ''}</h5>
                                    </li>

                                    <li>
                                        <span><img src="/images/p-birth.svg" alt="" /></span>
                                        {profile.title === "Mr." ? <h5>Male</h5> : ''}
                                        {profile.title === "Ms." ? <h5>Female</h5> : ''}
                                        {profile.title === "Mrs." ? <h5>Female</h5> : ''}

                                    </li>

                                </ul>
                            </div>
                        </div>
                        <div className="mdfy">
                            <div className="btn_fixed">
                              <Link to = "/web/customer/home/edit-profile" className="dnp">
                                <img src="/images/change-p.svg" className="mr-2" alt="Change profile" />Change profile
                              </Link>

                                <button type="submit" className="act" onClick = {() => logout(setAuth)}><img src="/images/p-logout.svg"
                                className="mr-2" alt="Sign out "  />Sign out</button>
                            </div>
                        </div>
                    </div>
  </>)
}

export default Profile
