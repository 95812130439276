import {IoClose} from 'react-icons/io5'

function Toast({message, hideToast}){

  const style = {
    position: "fixed",
    width: "400px",
    top: "90px",
    left: "calc(50% - 200px)",
    margin: "auto",
    borderRadius: "5px",
    padding:"16px",
    zIndex: "100"
  }

  const iconStyle = {
    position: "absolute",
    top: "8px",
    right: "16px",
    fontSize: "24px"
  }



  return(
    <div className = "error-dialog" >
      <p className = "m-0">{message ? message : ''}</p>
      <span className = "icon-cross" onClick ={() => hideToast()} style = {iconStyle}><IoClose /></span>
    </div>
  )
}

export default Toast
