import {Link} from 'react-router-dom'
import {useState} from 'react'
import Banner from './Banner.js'
import ProductCard from './ProductCard.js'
import ClaimForm from './ClaimForm.js'
import ClaimDetails from './ClaimDetails.js'
//import ProductCard from './ProductCard.js'

function WarrantyDetails({profile, product, warrantyItem, view, setView}){

  let date = new Date()
  let warrantyExp = new Date(warrantyItem.warrenty_to)
  let isExpired = date.getTime() > warrantyExp.getTime() ? true : false

  const share = () => {
    if (navigator.share) {
      navigator.share({url: window.location.href})
    }
  }

  if (view === 'warrantyDetails') {

    return(<>

      <div className="product_main">
         <div className="blc_bg pdindex">
            <div className="back_heading pdga">
              <Link to = "" className="container" onClick = {() => setView('warrantyList')}>
                 <img src="/images/share-arrow.svg" className="mr-2" alt="Product Warranty Details" />Product Warranty Details
              </Link>
            </div>

         {product && <div className="container">
             <div className="pro_head clearfix">
                 <div className="text_h">
                     <h2>
                        {product ? product.productName : ''}
                     </h2>
                     <div className="clear"></div>
                     <span>Brand&nbsp;:&nbsp; {product ? product.brandName : ''}&nbsp;&nbsp;|&nbsp;&nbsp;Size&nbsp;:&nbsp;{product ? product.size : ''}</span>
                     <div className="rtn_sr">
                         <span><i className="fa fa-star mr-1"></i>{product ? product.rating : ''}</span>
                         <a href="" onClick = {share}><i className="fa fa-share-alt" aria-hidden="true"></i></a>
                     </div>
                 </div>
             </div>
         </div>}

      </div>

         {product && <> <div className ="container">

         <Banner videoUri = {product.videoUri} images = {product ? product.images : []} />

         <div className="bottom-details mt-4">
             <div className="click_bcw d-flex justify-content-between">
                 <a href={product ? product.brandBrochure : ""}
                  className="rd_color"><img src="/images/down.svg" />
                 Brochure</a>
                 <a href={product ? product.brandUrl : ""} target ="_blank"><img src="/images/browser.svg" />Website</a>
             </div>

             <div className="productdetails mt-3">

                 {product ? <div dangerouslySetInnerHTML={{__html: product.description}} /> : ''}

             </div>

             <div className="socialmedia">

                 <ul className="tb_social">
                     {product ? <>
                       {product.social_link_array ? <li><a href={product.social_link_array.facebook}
                        className="fb" target="_blank"><i className="fa fa-facebook" aria-hidden="true"></i></a></li> : ''}

                       {product.social_link_array ? <li><a href={product.social_link_array.twitter}
                        className="tw" target="_blank"><i className="fa fa-twitter" aria-hidden="true"></i></a></li> : ''}

                        {product.social_link_array ? <li><a href={product.social_link_array.instagram}
                         className="ins" target="_blank"><i className="fa fa-instagram" aria-hidden="true"></i></a></li> : ''}

                         {product.social_link_array ? <li><a href={product.social_link_array.youtube}
                          className="you" target="_blank"><i className="fa fa-youtube-play" aria-hidden="true"></i></a></li> : ''}

                     </> : ''}

                 </ul>
             </div>


         </div>

           <span className="brdr">&nbsp;</span>

           <div className="wrty_pro">
               <ul className="d-flex justify-content-between">
                   <li>
                       <span>
                           Warranty Status
                           <b>{isExpired ? "Expired" : "Activated"}</b>
                       </span>
                   </li>
                   <li></li>
               </ul>
           </div>
           <span className="brdr">&nbsp;</span>

           <div className="wrny_dtlsp">
               <h5>Warranty Details</h5>
               <ul className="dts_wrt">
                   <li>Name</li>
                   <li>:</li>
                   <li>{warrantyItem? warrantyItem.name : ''}</li>
               </ul>
               <ul className="dts_wrt">
                   <li>Email</li>
                   <li>:</li>
                   <li>{profile? profile.email : ''}</li>
               </ul>
           </div>

           <div className="claimbx clearfix">
               {warrantyItem.claim_id ?

                 <Link to = "" className="claimbx_btn rdspd btnbd" onClick = {() => setView('claimDetails')}>
                  <img src="/images/view-clm.svg" alt="View Claim" />View Claim
                 </Link> :

                 <Link to = "" className="claimbx_btn rdspd" onClick = {() => setView('warrantyClaim')}>
                   <img src="/images/claim.svg" alt="Claim Now" />Claim Now
                 </Link>}

           </div>
           <span className="brdr">&nbsp;</span>

         </div>

         <div className="gr_bg  mt-4">
             <div className="slider_p">
                 <h3>Similar Products</h3>
                 <div className="scroll_s">
                 <div className="scrolling-wrapper">
                 {product.similarProducts ? product.similarProducts.map((product, i) => {
                   return(<ProductCard product = {product} key ={i} />)
                 }) : ''}
                 </div>
                 </div>
             </div>
         </div>
         <div className="text-center pt-3 mt-10 text_h">
             Powered By GENEFIED
         </div>
         <div className="ptfixed d-flex">
           
          <Link to = "/web/customer/home/activate-warranty" className={"AlreadyGratified"}
             onClick={ (event) => event.preventDefault()}>
             <img src={ "/images/checked-g.png"}
             className="mr-2" alt="Activate Warranty" />{isExpired ?  "warranty expired" : "warranty activated"}
           </Link>

         </div>
        </>}
      </div>
    </>)

  }

}

export default WarrantyDetails
