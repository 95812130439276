import useGM from '../hooks/useGM.js'

function useWarranty(){

  const {activateWarranty, warrantyHistory} = useGM()

  function activateProductWarranty(data, setError, setPopup, setUploadProgress){
    activateWarranty(data, setUploadProgress)
    .then(response => {
       //console.log(response)
      if (response.statusText === "OK" && response.status >= 200 && response.status < 400) {
        //return response.data
        if (response.data.status === '1') {
          setPopup({status : true, message: response.data.message})
          //setError({status: true, message: response.message})
        }else {
          setError({status: true, message: response.data.message})
        }
      }else {
        //show error
        setError({status: true, message: 'Error ' + response.status})
      }
    })
    .catch((error) => {
        console.log(error)
        setError({status: true, message: 'Something went wrong'})
    })
  }

  function warrantyList(setWarranties, setError){
    warrantyHistory()
    .then(response => {

      if (response.statusText === "OK" && response.status >= 200 && response.status < 400) {
        return response.json()
      }else {

        setError({status: true, message: response.text()})
      }
    })
    .then((response) => {

        if (response.status === '1') {
          setWarranties(response.data)
        }

        if (response.status === '0') {
          setError({status: true, message: response.message})
        }

    })
    .catch((error) => {
        setError({status: true, message: error})
    })
  }

  return {activateProductWarranty, warrantyList}
}

export default useWarranty
