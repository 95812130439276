import NavbarHome from './NavbarHome.js'
import Product from './Product.js'
import ActivateWarranty from './ActivateWarranty.js'
import Profile from './Profile.js'
import EditProfile from './EditProfile.js'
import WarrantyList from './WarrantyList.js'
import Toast from '../Toast.js'
import Preloader from '../Preloader.js'
import Terms from '../Terms.js'
import Privacy from '../Privacy.js'
import {Routes, Route} from 'react-router-dom'
import {useEffect, useState} from 'react'
import useGm from '../../hooks/useGM.js'
//import Cookies from 'js-cookie'
//import ClaimForm from './ClaimForm.js'
//import ClaimDetails from './ClaimDetails.js'
//import ClaimChat from './ClaimChat.js'
import { useLocation } from "react-router-dom";

function CustomerHome({profile, setProfile, setAuth, updateProfile, logout}){

  //const token = Cookies ? Cookies.get("auth_token") : ''

  const {singleQrcodeScan} = useGm()

  const [product, setProduct] = useState('')

  const [error, setError] = useState({status: false, message: ''})

  const [preloader, setPreloader] = useState(true)

  const location = useLocation();

  //get product data on page load
  useEffect(() => {

    if (location.pathname === "/web/customer/home/") {
      let qrcode = localStorage.getItem('qrcode')

      if (qrcode) {
        const gverify = 'http://gverify.me/?' + qrcode
        setPreloader(true)
        singleQrcodeScan({qrcode: gverify})
        .then(response => {
          if (response.statusText === "OK" && response.status >= 200 && response.status < 400) {
            return response.json()
          }else {
            //console.log(error)
            setError({status: true, message: response.text()})
          }
        })
        .then((response) => {
           setPreloader(false)

           if (response.status === '1') {
             setProduct(response.data)
           }
           if (response.status === '0') {
             setError({status: true, message: response.message})
           }

        })
        .catch((error) => {
            setError({status: true, message: toString(error)})
        })
      }else{
        setPreloader(false)
        setError({status: true, message: 'scan qrcode to get product data'})
      }
    }

 }, [location])

  //set profile on page refresh
  useEffect(() => {
    setProfile(JSON.parse(sessionStorage.getItem('profile')))
  }, [])


  return(
    <section className ="body_main">
      {preloader ? <Preloader /> : ''}
      {error.status ? <Toast message ={error.message} hideToast = {() => setError({...error, status: false})} /> : ''}
      <div className ="body_top">
        <NavbarHome {...{profile, setAuth, logout}} />

        <Routes>
          <Route path="/" element = {<Product {...{product}} />} />
          <Route path="/profile" element = {<Profile {...{profile, setAuth, logout}} />} />
          <Route path="/edit-profile" element = {<EditProfile {...{profile, setProfile, updateProfile}} />} />
          <Route path="/activate-warranty" element = {<ActivateWarranty {...{product}} />} />
          <Route path="/warranty-list" element = {<WarrantyList {...{product, profile}} />} />
          <Route path="/terms" element = {<Terms />} />
          <Route path="/privacy" element = {<Privacy />} />
        </Routes>

      </div>
    </section>
  )
}

export default CustomerHome
