import './App.css'
//import Index from './components/Index.js'
//import Web from './components/Web.js'
import Terms from './components/Terms.js'
import Customer from './components/Customer.js'
import {BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom'

const Home = () => {
   //const [searchParams, setSearchParams] = useSearchParams();
   const urlElements = window.location.href.split('/')
   //save qrcode value in session
   localStorage.setItem('qrcode', urlElements[5])
   //localStorage.setItem('qrcode', searchParams.get("value"))
   return( <Navigate to ="/web/customer/login" replace /> )
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path = "/web/customer/terms" element = { <Terms />} />
        <Route path = "/web/customer/*" element = { <Customer/> } />
        <Route path = "/web/code/*" element = { <Home /> } />
        <Route path = "/" element = { <Home /> } />
        <Route path = "*" element = { <Navigate to ="/web/customer/login" replace />} />
      </Routes>
    </Router>
  );
}

export default App;
