function ProductCard({product}){
  return(
    <div className="card">
        <div className="sld_pp">
            <a href="#">
                <div className="img_sp">
                    <img src={product ? product.product_image : ""} alt="" />
                </div>
                <div className="text_sp">
                    <h5>
                        {product ? product.productName : ''}
                    </h5>
                </div>
            </a>
        </div>
    </div>
  )
}

export default ProductCard
