import {useState, useEffect, useContext} from 'react'
import useGM from '../hooks/useGM.js'
import Cookies from 'js-cookie'

function useLogin(){

  const {sendOtp, customerLogin, customerUpdateProfile} = useGM()

  //send otp
  function sendOTP(data, setError, setTimer){
    //dummy
    //return setPage('otp')

    sendOtp(data)
    .then(response => {

      if (response.statusText === "OK" && response.status >= 200 && response.status < 400) {
        return response.json()
      }else {
        //show error
        setError({status: true, message: response.text()})
      }
    })
    .then((response) => {
       if (response.status === "1") {
         setTimer(59)
       }

       if (response.status === "0") {
         setError({status: true, message: response.message})
       }
    })
    .catch((error) => {
        setError({status: true, message: error})
    })

  }

  function login(data, setError, setAuth, setProfile){

    customerLogin(data)
    .then(response => {
      if (response.statusText === "OK" && response.status >= 200 && response.status < 300) {
        return response.json()
      }else {
        //show error
        setError({status: true, message: response.text()})
      }
    })
    .then((response) => {

       if (response.status === "1") {

         Cookies.set('auth_token', response.data.auth_token)
         Cookies.set('user_type', response.data.user_type)
         //store profile in session to prevent lost state on page refresh
         sessionStorage.setItem('profile', JSON.stringify(response.data))
         setProfile(response.data)
         setAuth(true)
       }

       if (response.status === "0") {
         setError({status: true, message: response.message})
       }

    })
    .catch((error) => {
        //show error
        setError({status: true, message: error})
    })

  }

  function updateProfile(profileInput, profile, setProfile, setError, setPopup){

    customerUpdateProfile(profileInput)
    .then(response => {
      if (response.statusText === "OK" && response.status >= 200 && response.status < 300) {
        return response.json()
      }else {
        //show error
        setError({status: true, message: response.text()})
      }
    })
    .then((response) => {

       if (response.status === "1") {

         sessionStorage.setItem('profile', JSON.stringify(response.data))

         setProfile({...profile, title: profileInput.title, name: profileInput.name,
           email: profileInput.email, mobile: profileInput.mobile})

         setPopup({status: true, message: response.message})
       }

       if (response.status === "0") {
         setError({status: true, message: response.message})
       }
    })
    .catch((error) => {
        //show error
        setError({status: true, message: error})
    })

  }

  function logout(setAuth){
    Cookies.remove('auth_token')
    Cookies.remove('user_type')
    setAuth(false)
  }

  function getLoginData(input, setInput){
    let baseUrl = 'https://genuinemark.org/mobileApi/admin/api/v2/test/'
    fetch(baseUrl+'customer/getLoginData', {
        method: 'POST',
        headers:{
          "Content-type": "application/json",
          "Token" : Cookies ? Cookies.get('auth_token') : ''
        },
        body : JSON.stringify({mobile: input.mobileNo})
    })
    .then((response) => {
      if (response.statusText === "OK" && response.status >= 200 && response.status < 400) {
        return response.json()
      }
    })
    .then(response => {
      if (response.status === '1') {
        setInput({...input, username:response.data.name})
      }
    })
  }

  return {sendOTP, login, updateProfile, logout, getLoginData}
}
 export default useLogin
