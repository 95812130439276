import {Link} from 'react-router-dom'
import Toast from '../Toast.js'
import ProgressBar from '../ProgressBar.js'
import {useState, useEffect} from 'react'
import useClaim from '../../hooks/useClaim.js'
import Cookies from 'js-cookie'
function ClaimChat({warrantyItem, setViewL3}){

  const {saveClaimData, getClaimChatsData, addNewChat, getResentClaimChatsData} = useClaim()

  //save chats state
  const [chats, setChats] = useState([])
  const [loader, setLoader] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(0)

  //load chats on initial render
  useEffect(() => {
    let data = {}
    data.claim_id = warrantyItem.claim_id
    data.user_type = Cookies.get('user_type')
    getClaimChatsData(data, setChats, setError)
  }, [])

  //add new chats state
  const [addChat, setAddChat] = useState({
    claim_id : warrantyItem.claim_id,
    text : '',
    media: '',
    user_type : Cookies ? Cookies.get('user_type') : null
  })

  const handlechange = (e) => {
    setAddChat({...addChat,
       [e.target.name] : e.target.value
    })
  }

  const selectMedia = (e) => {
    setAddChat({...addChat,
       media : e.target.files[0]
    })
  }

  const saveAddChat = () => {
    //console.log(addChat)
    setLoader(true)
    addNewChat(addChat, setError, setChats, setUploadProgress)
  }

  const [error, setError] = useState({status: false, message: ''})

  useEffect(() => {
    //make form fiels empty for new chats
    setAddChat({...addChat,
      text : '',
      media: '',
    })

    //hide loader when chats update
    if (chats) {
      setLoader(false)
      setUploadProgress(0)
    }
  }, [error, chats, setLoader, setUploadProgress])

  const MsgBox = ({chat}) => {
    //console.log(chat)
    let media = JSON.parse(chat.media)
    return(
      <li className={chat.user_type === "2" ? 'customer' : 'company'}>
          <div className="img_chat">
              <img src={chat ? chat.chat_user_logo : "/images/chat_user.svg"} alt="" />
          </div>
          <div className="msg_bx">
              <p>{chat ? chat.text : ''}</p>
          </div>
          {media ? media.map((item, i) => {
            if (item.type == 'image') {
              return( <img src={'https://genuinemark.org/upload/genuinemark/warranty-claim-media/'+ item.filename} alt={item.filename} />)
            }

            if (item.type == 'pdf') {
              return (<a href={'https://genuinemark.org/upload/genuinemark/warranty-claim-media/'+ item.filename} download>{item.filename}</a>)
            }

          }) : ''}
          <span className="rht_td">{chat ? chat.created_on : ''}</span>
      </li>
    )
  }

  return(<>
    {error.status ? <Toast message ={error.message} hideToast = {() => setError({...error, status: false})} /> : ''}

    {loader && <div className="popup showpop">
        <div className="popbx">

                <h3>Please wait...</h3>
                <div className = "mt-3 ">
                  <div className="upload_spin"></div>
                </div>
                <ProgressBar bgcolor={"#E00B0B"} completed={uploadProgress} />

          </div>
      </div>}

    <div className="fixed_btm">
                <div className="activeform">
                    <div className="back_heading pdga">
                       <Link to = "" className="container" onClick = {() => setViewL3('claimDetails')}>
                          <img src="/images/share-arrow.svg" className="mr-2" alt="Claim Warranty Form" /> Claim Warranty Chat
                       </Link>

                    </div>
                    <div className="container">
                        <div className="boxpd">
                            <div className="chalpanel">
                                <ul className="clearfix">
                                    {chats ? chats.map((chat, i) => {
                                      return <MsgBox chat = {chat} key = {i} />
                                    }) : ''}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
        <div className="btn_fixed bgblock">
            <div className="chat_input">
                <div className="ctbx">
                    <input type="text" className="ctwid"
                    placeholder="Type a message"
                    name = "text"
                    value = {addChat.media ? addChat.media.name : addChat.text}
                    onChange = {handlechange} />

               <div className="attechmt">
                   <i className="fa fa-paperclip" aria-hidden="true"></i>
                            <input type="file" placeholder=""
                              onChange = {selectMedia}
                              accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"/>
                        </div>
                 </div>
                <div className="btnsend_cht">
                    <button type="submit" className="btncirl_ct"><i className="fa fa-send" onClick = {() => saveAddChat()}></i></button>
                </div>

            </div>
        </div>
  </>)
}

export default ClaimChat
