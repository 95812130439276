import { Link, Navigate } from "react-router-dom";
import Toast from "../Toast.js";
import ProgressBar from "../ProgressBar.js";
import { useState, useEffect } from "react";
import useWarranty from "../../hooks/useWarranty.js";
import { Modal, Carousel } from "react-bootstrap";
import bill1 from "../../assets/images/bill1.jpeg";
import bill2 from "../../assets/images/bill2.jpeg";
import bill3 from "../../assets/images/bill3.jpeg";

function ActivateWarranty({ product }) {
  const [showModal, setShowModal] = useState(false);
  const bills = [bill1, bill2, bill3];
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");

  const daysOptions = Array.from({ length: 31 }, (_, i) => i + 1);
  const monthsOptions = Array.from({ length: 12 }, (_, i) => {
    const month = new Date(0, i).toLocaleString("default", { month: "long" });
    return { value: i + 1, label: month };
  });
  const yearsOptions = Array.from(
    { length: 100 },
    (_, i) => new Date().getFullYear() - i
  );

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };
  
  const handleMonthChange = (e) => {
    setSelectedMonth(e.target.value);
  };
  
  const handleYearChange = (e) => {
    setSelectedYear(e.target.value);
  };
  

  const isDateComplete =
    selectedDate !== "" && selectedMonth !== "" && selectedYear !== "";

  // Utility function to get the number of days in a month
  const getDaysInMonth = (month, year) => {
    return new Date(year, month, 0).getDate();
  };

  // Generate days based on the selected month and year
  const daysInSelectedMonth = getDaysInMonth(selectedMonth, selectedYear);
  const daysOptionsForSelectedMonth = Array.from(
    { length: daysInSelectedMonth },
    (_, i) => i + 1
  );

  const handleOpenModal = (image) => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  let warranty_activation = JSON.parse(
    product.vendor_forms.warranty_activation
  );

  const { activateProductWarranty } = useWarranty();

  let userProfile = sessionStorage
    ? JSON.parse(sessionStorage.getItem("profile"))
    : "";
  //console.log(userProfile)
  if (userProfile && userProfile.dob) {
    // var month_diff = Date.now() - userProfile.dob.getTime();
    // //convert the calculated difference in date format
    // var age_dt = new Date(month_diff);
    // //extract year from date
    // var year = age_dt.getUTCFullYear();
    // //now calculate the dob of the user
    // var dob = Math.abs(year - 1970);
    var gender = userProfile.gender;
    if (gender === "male" || gender === "Male") {
      var title = "Mr.";
    }
  }

  const [warranty, setWarranty] = useState({
    title: title ? title : "Mr.",
    name: userProfile ? userProfile.name : "",
    email: userProfile ? userProfile.email : "",
    pincode: "",
    invoice_image: "",
    dob: "",
    qrcode: product.qrId,
    vendor_id: product.vendor_id,
  });

  const selectInvoice = (e) => {
    setWarranty({ ...warranty, invoice_image: e.target.files[0] });
  };

  //console.log(warranty)

  const [error, setError] = useState({ status: false, message: "" });
  const [popup, setPopup] = useState({ status: false, message: "" });
  const [backPage, setBackpage] = useState(false);
  const [loader, setLoader] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  //console.log(uploadProgress)

  const handleChange = (e) => {
    setWarranty({ ...warranty, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    if (selectedDate !== "" && selectedMonth !== "" && selectedYear !== "") {
      const selectedDateObject = new Date(selectedYear, selectedMonth - 1, selectedDate);
      const date = selectedDateObject.getDate();
      let month = selectedDateObject.getMonth() + 1;
      const year = selectedDateObject.getFullYear();
      month = ('0' + month).slice(-2);
      const dobString = `${year}-${month}-${date}`;
      console.log(dobString, "Date of Birth");
      setWarranty((prevWarranty) => ({
        ...prevWarranty,
        dob: dobString,
      }));
    }
  }, [selectedDate, selectedMonth, selectedYear]);
  
  

  const submitWarranty = () => {
    console.log(warranty);

    if (!warranty.title) {
      setError({ ...error, status: true, message: "Please enter title" });
    }

    if (!warranty.name) {
      setError({ ...error, status: true, message: "Please enter name" });
    }

    if (!warranty.email) {
      setError({
        ...error,
        status: true,
        message: "Please enter email address",
      });
    } else if (
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(warranty.email)
    ) {
      setError({ ...error, status: true, message: "Invalid email address" });
    }

    if (!warranty.pincode) {
      setError({ ...error, status: true, message: "Please enter pincode" });
    } else if (warranty.pincode.toString().length !== 6) {
      setError({ ...error, status: true, message: "Invalid pincode" });
    }

    if (!warranty.invoice_image) {
      setError({ ...error, status: true, message: "Please upload invoice" });
    } 
    

    if (!warranty.dob) {
      setError({ ...error, status: true, message: "Please enter dob" });
    }

    if (
      warranty.title &&
      warranty.name &&
      warranty.email &&
      warranty.pincode &&
      warranty.dob &&
      warranty.invoice_image &&
      warranty.qrcode &&
      warranty.vendor_id
    ) {
      setError({ ...error, status: false, message: "" });

      setLoader(true);
      activateProductWarranty(warranty, setError, setPopup, setUploadProgress);
    }
  };

  const hidePopup = () => {
    setPopup({ status: false });
    setBackpage(true);
  };

  useEffect(() => {
    if (popup.status === true || popup.status || error.status === true) {
      setLoader(false);
      setUploadProgress(0);
    }
  }, [error, popup, setLoader, setUploadProgress]);

  let profile = JSON.parse(sessionStorage.getItem("profile"));

  if (!backPage) {
    return (
      <>
        {error.status ? (
          <Toast
            message={error.message}
            hideToast={() => setError({ ...error, status: false })}
          />
        ) : (
          ""
        )}

        {popup.status && (
          <div className="popup showpop">
            <div className="popbx">
              <span className="close_btn" onClick={() => hidePopup()}>
                <img src="/images/close.svg" alt="" />
              </span>
              <div className="poptxt">
                <img src="/images/userpop.svg" alt="" />
                <h3>Dear {profile.name}.</h3>

                {popup.message === "Warranty Already Activated" ? (
                  <p>Warranty Already Activated</p>
                ) : (
                  <p>
                    Your request is submitted and Warranty has been Activated
                    successfully.
                  </p>
                )}
              </div>
            </div>
          </div>
        )}

        {loader && (
          <div className="popup showpop">
            <div className="popbx">
              <img src="/images/userpop.svg" alt="" />
              <h3>Uploading...</h3>
              <div className="mt-3 ">
                <div className="upload_spin"></div>
              </div>
              <ProgressBar bgcolor={"#E00B0B"} completed={uploadProgress} />
            </div>
          </div>
        )}

        <div className="blc_bg pda_blc">
          <div className="container">
            <div className="back_heading">
              <Link to="/web/customer/home">
                <img
                  src="/images/share-arrow.svg"
                  className="mr-2"
                  alt="Activate Warranty Registration"
                />
                Activate Warranty Registration
              </Link>
            </div>

            <div className="active_in">
              <div className="diseble_frm">
                <span>Product Name</span>
                <h6>{product ? product.productName : ""}</h6>
              </div>
              <div className="diseble_frm nonebr">
                <span>Product Code</span>
                <h6>{product ? product.product_code : ""}</h6>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="boxpd  fixed_btm">
            <div className="activeform">
              <div className="active_in mt-3">
                <div className="d-flex">
                  {warranty_activation
                    ? warranty_activation.map((field, i) => {
                        //console.log(field)
                        if (field.key === "title") {
                          return (
                            <div
                              className="position-relative input_ds WD1"
                              key={field.key}
                            >
                              <select
                                className="inputbox inht dropd"
                                name={field.key}
                                selected={warranty.title}
                                onChange={handleChange}
                              >
                                <option value="Mr.">MR.</option>
                                <option value="Ms.">MS.</option>
                                <option value="Mrs.">MRS.</option>
                              </select>
                              <i className="fa fa-angle-down select-arrow"></i>
                            </div>
                          );
                        }

                        if (field.key === "name") {
                          return (
                            <div
                              className="position-relative input_ds  WD2"
                              key={field.key}
                            >
                              <input
                                type="text"
                                placeholder="Enter Name *"
                                className="inputbox inht"
                                name={field.key}
                                onChange={handleChange}
                                value={warranty.name}
                              />
                            </div>
                          );
                        }
                      })
                    : ""}
                </div>

                {warranty_activation
                  ? warranty_activation.map((field, i) => {
                      if (field.key === "email") {
                        return (
                          <div
                            className="position-relative input_ds"
                            key={field.key}
                          >
                            <input
                              type="email"
                              placeholder={
                                field.place_holder ? field.place_holder +" *" : ""
                              }
                              name={field.key}
                              onChange={handleChange}
                              value={warranty.email}
                              className="inputbox inht"
                            />
                          </div>
                        );
                      }

                      if (field.key === "pincode") {
                        return (
                          <div
                            className="position-relative input_ds"
                            key={field.key}
                          >
                            <input
                              type="number"
                              placeholder={
                                field.place_holder
                                  ? field.place_holder + " *"
                                  : ""
                              }
                              name={field.key}
                              onChange={handleChange}
                              value={warranty.pincode}
                              className="inputbox inht"
                            />
                          </div>
                        );
                      }

                      if (field.key === "invoice_image") {
                        return (
                          <>
                            <label>
                              Please upload the correct invoice image *{" "}
                              <i
                                className="fa fa-info-circle"
                                onClick={() => handleOpenModal()}
                              ></i>
                            </label>
                            <div
                              className="position-relative input_ds"
                              key={field.key}
                            >
                              <div className="inputbox inht FileUpload_up">
                                <span>Invoice</span>
                                <input
                                  type="file"
                                  placeholder={
                                    field.place_holder ? field.place_holder : ""
                                  }
                                  name={field.key}
                                  onChange={selectInvoice}
                                  accept="image/jpeg,image/png,image/jpg,.pdf"
                                />
                              </div>
                            </div>
                          </>
                        );
                      }

                      if (field.key === "age") {
                        return (
                          <div className="position-relative input_ds">
                            <label>
                              Please Enter your correct birth date for insurance
                              *
                            </label>
                            <div style={{ display: "flex" }}>
                              <select
                                value={selectedDate}
                                onChange={handleDateChange}
                                className="inputbox inht"
                              >
                                <option value="">Day</option>
                                {daysOptionsForSelectedMonth.map((day) => (
                                  <option key={day} value={day}>
                                    {day}
                                  </option>
                                ))}
                              </select>

                              <select
                                value={selectedMonth}
                                onChange={handleMonthChange}
                                className="inputbox inht"
                              >
                                <option value="">Month</option>
                                {monthsOptions.map((month) => (
                                  <option key={month.value} value={month.value}>
                                    {month.label}
                                  </option>
                                ))}
                              </select>

                              <select
                                value={selectedYear}
                                onChange={handleYearChange}
                                className="inputbox inht"
                              >
                                <option value="">Year</option>
                                {yearsOptions.map((year) => (
                                  <option key={year} value={year}>
                                    {year}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        );
                      }
                    })
                  : ""}
              </div>
            </div>
          </div>
        </div>

        <div className="btn_fixed">
          <button
            type="submit"
            className="logbtn"
            id="popshow"
            onClick={submitWarranty}
          >
            <img src="/images/send.svg" alt="Submit" className="mr-2" />
            Submit
          </button>
        </div>

        <Modal
          show={showModal}
          onHide={handleCloseModal}
          size="xl"
          centered
          dialogClassName="custom-modal"
        >
          <Modal.Header className="d-flex justify-content-between">
            <button type="button" className="close" onClick={handleCloseModal}>
              <span aria-hidden="true" className="larger-close-button">
                &times;
              </span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <Carousel
              prevLabel={null}
              nextLabel={null}
              indicators={false}
              variant="dark"
            >
              {bills.map((image, index) => (
                <Carousel.Item key={index}>
                  <img
                    src={image}
                    className="img-size"
                    alt={`Slide ${index}`}
                  />
                </Carousel.Item>
              ))}
            </Carousel>
          </Modal.Body>
          <Modal.Footer>
            Please Upload invoice in jpg, png,jpeg, or pdf format.
          </Modal.Footer>
        </Modal>

        <style>
          {`
  .custom-modal {
    max-width: 90%;
    margin: 0 auto;
    padding: 2rem;
    height: 50%;
  }

  .img-size {
    height: 300px; /* Adjust the desired height */
    width: auto;
    object-fit: contain;
    margin: auto;
    padding: auto;
    display: flex;
  }

  .gallery-image {
    max-height: 200px;
    width: auto;
    object-fit: cover;
  }

  .carousel-indicators li {
    background-color: #000; // Change this color to adjust the indicators' background color
    width: 10px; // Change this value to adjust the width of the indicators
    height: 2px; // Change this value to adjust the height of the indicators
  }

  .carousel-indicators .active {
    background-color: #fff; // Change this color to adjust the active indicator's background color
  }

  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    filter: invert(1);
  }

  .larger-close-button {
    font-size: 2rem; /* Adjust the desired font size for the close button */
  }
`}
        </style>
      </>
    );
  } else {
    return <Navigate to="/web/customer/home/" />;
  }
}

export default ActivateWarranty;
