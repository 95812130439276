function Terms(){
  return(<>
    <div className="blue_Bg">
            <div className="container">
                <div className="back_heading d-flex justify-content-between align-items-center mb-0">
                    <h1>
                        Term & Conditions
                    </h1>
                    <span><img src="/images/term.svg" alt="Term & Conditions" /></span>
                </div>
            </div>
        </div>
        <div className="container">
            <div className="paratxt">
                

            </div>

        </div>
  </>)
}

export default Terms
