import {useState, useEffect} from 'react'
import {Navigate, Link} from 'react-router-dom'
import Toast from './Toast.js'

function Login({sendOTP, login, setAuth, setProfile, getLoginData}){

  const [page, setPage] = useState('login')

  const [input, setInput] = useState({
    mobileNo : '',
    username : '',
  })

  const [error, setError] = useState({status: false, message: ''})
  const [loader, setLoder] = useState(false)
  const [otpBox, setOtpBox] = useState(false)
  //const [popup, setPopup] = useState(false)

  const handleChange = (e) => {

    setInput({...input,
      [e.target.name] : e.target.value
    })
  }

  const handleSubmit = (e) => {

    if (!input.mobileNo) {
       setError({
         status: true,
         message: "Please enter your mobile no"
       })
    }

    if (input.mobileNo.toString().length !== 10) {
       setError({
         status: true,
         message: "Invalid mobile number"
       })
    }

    if (!input.username) {
       setError({
         status: true,
         message: "Please enter your name"
       })
    }

    if (!input.mobileNo && !input.username) {
      setError({
        status: true,
        message: "Please enter mobile number and name"
      })
    }

    if (input.mobileNo && input.username && input.mobileNo.toString().length === 10) {
      setError({
        status: false,
        message: ""
      })
      setOtpBox(true)
      sendOTP({mobileNo: input.mobileNo}, setError, setTimer)
    }
  }


  const [otp, setOtp] = useState({
    otpdigit1: '',
    otpdigit2: '',
    otpdigit3: '',
    otpdigit4: '',
    otpdigit5: ''
  })

  const [timer, setTimer] = useState(null)

  const [resendOtpBtn, setResendOtpBtn] = useState(false)

  const handleOtpInput = (e) => {
    setOtp({...otp,
      [e.target.name]: e.target.value
    })
  }

  const inputfocus = (elmnt) => {

    if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
      const next = elmnt.target.tabIndex - 2;
      if (next > -1) {
        elmnt.target.form.elements[next].focus()
      }
    }
    else {

        const next = elmnt.target.tabIndex;
        if (next < 5) {
          elmnt.target.form.elements[next].focus()
        }
    }

  }


  //request login
  const submitLogin = (e) => {
    e.preventDefault()
    let num = otp.otpdigit1 + otp.otpdigit2 + otp.otpdigit3 + otp.otpdigit4 + otp.otpdigit5

    if (num.length < 5) {
      setError({...error,
        status: true,
        message: "Invalid OTP"
      })
    }

    if (!num) {
      setError({...error,
        status: true,
        message: "Please enter OTP"
      })
    }
    if (input.mobileNo && num.length == 5) {
      login({mobileNo: input.mobileNo, otp: parseInt(num, 10)}, setError, setAuth, setProfile)
    }

  }

  const resendOTP = () => {
    //claear otp input
    setOtp({
      otpdigit1: '',
      otpdigit2: '',
      otpdigit3: '',
      otpdigit4: '',
      otpdigit5: ''
    })

    if (error.status == false && error.message == '') {

      sendOTP({mobileNo: input.mobileNo}, setError, setTimer)
    //setTimer(59)
    }
  }

  //reduce timer count
  useEffect(() => {
    const interval =
        timer > 0 && setInterval(() => setTimer(timer - 1), 1000)
        return () => clearInterval(interval)
  }, [timer])


  //enable resend button
  useEffect(() => {
    if (timer === 0) {
      setResendOtpBtn(true)
    }else{
      setResendOtpBtn(false)
    }
  }, [timer])

  //auto fetch name
  useEffect(() => {
    if (input.mobileNo.toString().length === 10 && input.username == '') {
      getLoginData(input, setInput)
    }
  },[input])

  useEffect(() => {
    if (page === 'otp') {
      setTimer(59)
    }
  }, [page])

  if (page === 'login') {
    return(<>

      <div className ="bglog">
        <section className ="loginbox">
          {error.status ? <Toast message ={error.message} hideToast = {() => setError({...error, status: false})} /> : ''}
          <div className ="bg_ptrn">
            <div className = "logo_circle">
               <img src = "/images/logo-vega.png" alt = "" className ="imgrs"/>
            </div>

            <div className="login_bx">
              {loader &&
                <div className = "sm_loader_ctr">
                  <div className ="sm_loader"></div>
                </div>
              }
              <h1>Welcome Back!</h1>
              <span className="small_txt">Login To Your Account</span>
              <div className="form_in">

                    <div className="position-relative ">
                        <span className="nbric">&nbsp;<img src="/images/phone.png" className="fxt" /></span>
                        <input type="number"
                         name= "mobileNo"
                         placeholder="10 digit mobile no"
                         className="input_bx nbr"
                         value = {input.phone}
                         maxLength = "10"
                         onChange = {handleChange}
                         />
                    </div>
                    <div className="position-relative">
                        <span className="nbric">&nbsp;<img src="/images/user-log.png" className="fxt" /></span>
                        <input type="text"
                        name="username"
                        placeholder="Enter User Name"
                        className="input_bx"
                        value = {input.username}
                        onChange = {handleChange} />
                    </div>

                    <div className="position-relative text-center">
                      <button type="button" className="optbtn" disabled = {(timer && timer >= 0) ? true : false}
                      onClick = {() => handleSubmit()}>Get OTP</button>
                    </div>

                    {otpBox && <div className="login_bx_otp">
                        <h2>Verification Code</h2>
                        <span className="small_txt">
                            Please enter Verification code sent to your mobile
                        </span>

                        <div className="form_in">
                           <form onSubmit = {submitLogin}>
                              <div className="inbx">

                                  <div className="d-flex justify-content-center otp_bx">
                                      <div className="position-relative">
                                          <input type="number"
                                           name= "otpdigit1"
                                           placeholder=""
                                           className="input_bx otp_in"
                                           onChange = {handleOtpInput}
                                           tabIndex="1"
                                           maxLength="1"
                                           onKeyUp={e => inputfocus(e)}
                                           value = {otp[0]} />
                                      </div>
                                      <div className="position-relative">
                                          <input type="number"
                                           name= "otpdigit2"
                                           placeholder=""
                                           className="input_bx otp_in"
                                           onChange = {handleOtpInput}
                                           tabIndex="2"
                                           maxLength="1"
                                           onKeyUp={e => inputfocus(e)}
                                           value = {otp[1]}  />
                                      </div>

                                      <div className="position-relative">
                                          <input type="number"
                                          name= "otpdigit3"
                                          placeholder=""
                                          className="input_bx otp_in"
                                          onChange = {handleOtpInput}
                                          tabIndex="3"
                                          maxLength="1"
                                          onKeyUp={e => inputfocus(e)}
                                          value = {otp[2]}  />
                                      </div>

                                      <div className="position-relative">
                                          <input type="number"
                                          name= "otpdigit4"
                                          placeholder=""
                                          className="input_bx otp_in"
                                          onChange = {handleOtpInput}
                                          tabIndex="4"
                                          maxLength="1"
                                          onKeyUp={e => inputfocus(e)}
                                          value = {otp[3]}  />
                                      </div>

                                      <div className="position-relative">
                                          <input type="number"
                                          name= "otpdigit5"
                                          placeholder=""
                                          className="input_bx otp_in"
                                          onChange = {handleOtpInput}
                                          tabIndex="5"
                                          maxLength="1"
                                          onKeyUp={e => inputfocus(e)}
                                          value = {otp[4]}  />
                                      </div>

                                  </div>
                                  <div className="lnbtom d-flex">
                                      <span>{timer} seconds left</span>
                                      <button type="button"
                                       className= {resendOtpBtn ? "text-white rsd" : "rsd"}
                                       disabled = {resendOtpBtn ? false : true}
                                       onClick = {() => resendOTP()}
                                       >Resend OTP</button>
                                  </div>
                              </div>


                            </form>
                        </div>

                    </div>}

                    <div className="btn_main text-center">
                        <button type="submit" value = "submit" id="verifyno" className="loginbtn"
                        onClick = {(e) => submitLogin(e)}>Login</button>
                    </div>
              </div>

            </div>
            <div className="shapebox">
                <div className="sp_s position-relative">
                    <img src="/images/shape.png" alt="" className="imgrs"/>
                    <div className="ps">
                        <p>Get  ICICI Bank Insurance amount 1,00,000 INR on purchasing of Vega helmate .</p>
                        <Link to = "/web/customer/terms">*T&C apply</Link>
                    </div>
                </div>
            </div>
          </div>
        </section>
      </div>
    </>)
  }
  //
  // if (page === 'otp') {
  //   return(
  //     <div className ="bglog">
  //       <section className ="loginbox">
  //         {error.status ? <Toast message ={error.message} hideToast = {() => setError({...error, status: false})} /> : ''}
  //         <div className ="bg_ptrn">
  //           <div className = "logo_circle">
  //              <img src = "/images/logo-vega.png" alt = "" className ="imgrs"/>
  //           </div>
  //
  //           <div className="login_bx">
  //
  //           </div>
  //         </div>
  //       </section>
  //     </div>
  //   )
  // }
  // //
  // if (page === 'home') {
  //   return <Navigate to = "/web/customer/home" />
  // }

}

export default Login
