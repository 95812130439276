function Popup({show, message, hidePopup}){
  let profile = JSON.parse(sessionStorage.getItem('profile'))
  return(
    <div className={show ? "popup showpop" : "popup"}>
            <div className="popbx">
                <span className="close_btn" onClick = {hidePopup}><img src="/images/close.svg" alt="" /></span>
                <div className="poptxt">
                    <img src="/images/userpop.svg"  alt=""/>
                    <h3>Dear {profile ? profile.name : ''}</h3>
                    <p>
                      {message}
                    </p>
                </div>
            </div>
    </div>
  )
}

export default Popup
