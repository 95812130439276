import Banner from './Banner.js'
import ProductCard from './ProductCard.js'
import {Link} from 'react-router-dom'


function Product({product}){
  //console.log(product)
  const share = () => {
    if (navigator.share) {
      navigator.share({url: window.location.href})
    }
  }

  if (product) {
    return(<>

      <div className="product_main">
         <div className="blc_bg pdindex">
           <div className="container">
               <div className="pro_head clearfix">
                   <div className="text_h">
                       <h2>
                          {product ? product.productName : ''}
                       </h2>
                       <div className="clear"></div>
                       <span>Brand&nbsp;:&nbsp; {product ? product.brandName : ''}&nbsp;&nbsp;|&nbsp;&nbsp;Size&nbsp;:&nbsp;{product ? product.size : ''}</span>
                       <div className="rtn_sr">
                           <span><i className="fa fa-star mr-1"></i>{product ? product.rating : ''}</span>
                           <a href="" onClick = {share}><i className="fa fa-share-alt" aria-hidden="true"></i></a>
                       </div>
                   </div>

               </div>

           </div>
         </div>

         <div className ="container">

           <Banner videoUri = {product.videoUri} images = {product ? product.images : []} />

           <div className="bottom-details mt-4">
               <div className="click_bcw d-flex justify-content-between">
               {product.brandBrochure ? <a href={product.brandBrochure}
                className="rd_color"><img src="/images/down.svg" />
               Brochure</a> : <p> </p>}

               <a href={product ? product.brandUrl : ""} target ="_blank"><img src="/images/browser.svg" />Website</a>

               </div>

               <div className="productdetails mt-3">

                   {product ? <div dangerouslySetInnerHTML={{__html: product.description}} /> : ''}

                   <div className="focusbox">
                       <p>You will get cover of <span>INR 100000 rupees accidental insurance from ICICI bank</span> on behalf of vega helmets using digital warranty activation</p>
                   </div>

               </div>

               <div className="socialmedia">

                   <ul className="tb_social">
                       {product ? <>
                         {product.social_link_array ? <li><a href={product.social_link_array.facebook}
                          className="fb" target="_blank"><i className="fa fa-facebook" aria-hidden="true"></i></a></li> : ''}

                         {product.social_link_array ? <li><a href={product.social_link_array.twitter}
                          className="tw" target="_blank"><i className="fa fa-twitter" aria-hidden="true"></i></a></li> : ''}

                          {product.social_link_array ? <li><a href={product.social_link_array.instagram}
                           className="ins" target="_blank"><i className="fa fa-instagram" aria-hidden="true"></i></a></li> : ''}

                           {product.social_link_array ? <li><a href={product.social_link_array.youtube}
                            className="you" target="_blank"><i className="fa fa-youtube-play" aria-hidden="true"></i></a></li> : ''}

                       </> : ''}

                   </ul>
               </div>


           </div>
         </div>

         <div className="gr_bg  mt-4">
             <div className="slider_p">
                 <h3>Similar Products</h3>
                 <div className="scroll_s">
                 <div className="scrolling-wrapper">
                 {product.similarProducts ? product.similarProducts.map((product, i) => {
                   return(<ProductCard product = {product} key ={i} />)
                 }) : ''}
                 </div>
                 </div>
             </div>
         </div>
         <div className="text-center pt-3 mt-10 text_h">
             Powered By GENEFIED
         </div>
         <div className="ptfixed d-flex">

             <Link to = "/web/customer/home/activate-warranty" className={product.iswarrenty ? "AlreadyGratified" : " yellowbtn" }
              onClick={ product.iswarrenty ? (event) => event.preventDefault()  : ''}>
               <img src={product.iswarrenty ? "/images/checked-g.png" : "/images/activew.svg" }
               className="mr-2" alt="Activate Warranty" />{product.iswarrenty ? "warranty activated" : "activate warranty" }
             </Link>

         </div>
      </div>
    </>)
  }
}

export default Product
