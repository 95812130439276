function Privacy(){
  return(<>
    <div className="blue_Bg">
          <div className="container">
              <div className="back_heading d-flex justify-content-between align-items-center mb-0">
                  <h1>
                      Privacy Policy
                  </h1>
                  <span><img src="/images/policy.svg" alt="Privacy Policy" /></span>
              </div>
          </div>
      </div>
      <div className="container">
          <div className="paratxt">
              

          </div>

      </div>
  </>)
}

export default Privacy
