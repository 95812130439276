import {Link} from 'react-router-dom'
import {useState, useEffect} from 'react'
import ClaimChat from './ClaimChat.js'
import useClaim from '../../hooks/useClaim.js'
import Toast from '../Toast.js'

function ClaimDetails({warrantyItem, setView}){

  const {getWarrantyClaimData} = useClaim()

  const [claims, setClaims] = useState('')

  const [imagePopup, setImagePopup] = useState({status: false, image: ''})

  //console.log(claims)
   //console.log(claims)
  // created_on: "2021-10-20 13:07:12",
  //    description: "bad",
  //    id: 57,
  //    image: ["https://genuinemark.org/upload/genuinemark/warranty-claim/18107-1634715432.2023-165154.png"], it a array
  //    remarks: "",
  //    status: "Penidng"

  //console.log(warrantyItem)

  const [error, setError] = useState({status: false, message: ''})

  useEffect(() => {
    getWarrantyClaimData(warrantyItem.id, setClaims, setError)
  }, [])

  //manage state of active view
  const [viewL3, setViewL3] = useState('claimDetails')

  const ImageView = ({image}) => {
    return(
      <li>
          <img src={image ? image : ""} data-fancybox="gallery" alt="" onClick={() => setImagePopup({status: true, image: image})} />
      </li>
    )
  }

  if (viewL3 === 'claimDetails') {
    return(<>
      {error.status ? <Toast message ={error.message} hideToast = {() => setError({...error, status: false})} /> : ''}
      <div className="blc_bg pda_blc" style = {{height:"100vh"}}>
              <div className="container">
                  <div className="back_heading">
                      <Link to = "" onClick = {() => setView('warrantyList')}>
                         <img src="/images/share-arrow.svg" className="mr-2" alt="Claim Warranty Form" />Claim Warranty Details
                      </Link>
                  </div>

                  {claims ? claims.map((claim, i) => {
                     return (
                       <div className="boxpd fixed_btm" key ={i}>
                           <div className="activeform">
                               <div className="active_in">
                                   <div className="diseble_frm">
                                       <span>Claim Status</span>
                                       {claim.status ==='Pending' ? <h6><span className="process_color allstatus"></span>On Process</h6> : ''}
                                       {claim.status ==='Penidng' ? <h6><span className="process_color allstatus"></span>On Process</h6> : ''}
                                       {claim.status ==='Rejected' ? <h6><span className="Reject_color text-danger allstatus"></span>Rejected</h6> : ''}
                                       {claim.status ==='Approved' ? <h6><span className="Approve_color text-success allstatus"></span>Approved</h6> : ''}

                                   </div>

                                   <div className="diseble_frm">
                                       <span>Claim Date</span>
                                       <h6>{claim.created_on}</h6>
                                   </div>
                                   <div className="diseble_frm">
                                       <span>Batch Code</span>
                                       <h6>{warrantyItem.batch_code}</h6>
                                   </div>

                                   <div className="diseble_frm">
                                       <h6>Product Damage</h6>
                                       <p>{claim.description}</p>
                                       <div className="img_zm">
                                           <ul className="img_rv">
                                               {claim.image ? claim.image.map((image, i) => {
                                                 return(<ImageView image = {image} key ={i}/>)
                                               }) : ''}
                                           </ul>
                                       </div>
                                   </div>

                               </div>
                           </div>
                       </div>
                     )
                  }) : ''}

              </div>
          </div>

          {imagePopup.status && <div className="popup showpop">
                        <div className="popbx">

                            <div className="poptxt">
                                <img src={imagePopup.image} />
                            </div>
                          <button className="btn_ok" onClick={() => setImagePopup({status: false, image: ''})}>OK</button>
                        </div>
            </div>}

          <div className="btn_fixed">
              <Link to = "" className="chtbtn" onClick = {() => setViewL3('claimChat')}>
                 <img src="/images/chatbtn.svg" alt="Chat Now" className="mr-2" />Chat Now
              </Link>
          </div>
    </>)
  }

  if (viewL3 === 'claimChat') {
    return(<ClaimChat {...{warrantyItem, setViewL3}} />)
  }

}

export default ClaimDetails
