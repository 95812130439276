import useGM from '../hooks/useGM.js'

function useClaim() {

  const {createWarrantyClaim, getWarrantyclaim, getClaimChats, addClaimChat} = useGM()

  function saveClaimData(data, setError, setPopup, setUploadProgress, setLoader){
    //console.log(data)
    createWarrantyClaim(data, setUploadProgress)
    .then(response => {
      //console.log(response)
      if (response.statusText === "OK" && response.status >= 200 && response.status < 400) {
        return response
      }else {
        //show error
        setError({status: true, message: 'Error ' + response.status})
      }
    })
    .then((response) => {
        if (response.data.status === '1') {
          setPopup({status: true, message: response.data.message})
        }else{
          setError({status: true, message: response.data.message})
        }
    })
    .catch((error) => {
         console.log(error)
        setError({status: true, message: 'Something went wrong'})
    })
  }

  function getWarrantyClaimData(warranty_id, setClaims, setError){
    getWarrantyclaim(warranty_id)
    .then(response => {

      if (response.statusText === "OK" && response.status >= 200 && response.status < 400) {
        return response.json()
      }else {
        //show error
        setError({status: true, message: response.text()})
      }
    })
    .then((response) => {
        if (response.status === '1') {
          //console.log(response)
          setClaims(response.data)
        }
        else{
          setError({status: true, message: response.message})
        }

    })
    .catch((error) => {
        setError({status: true, message: error})
    })
  }

  function getClaimChatsData(data, setChats, setError){
    getClaimChats(data)
    .then(response => {

      if (response.statusText === "OK" && response.status >= 200 && response.status < 400) {
        return response.json()
      }else {
        //show error
        setError({status: true, message: response.text()})
      }
    })
    .then((response) => {

        if (response.status === '1') {
          setChats(response.data)
        }
        else{
          setError({status: true, message: response.message})
        }

    })
    .catch((error) => {
        //console.log(error)
        setError({status: true, message: error})
    })
  }

  function addNewChat(data, setError, setChats, setUploadProgress){
    addClaimChat(data, setUploadProgress)
    .then(response => {

      if (response.statusText === "OK" && response.status >= 200 && response.status < 400) {

        if (response.data.status === '1') {
          getClaimChatsData({claim_id: data.claim_id, user_type: data.user_type}, setChats, setError)
        }
        else{
          setError({status: true, message: response.data.message})
        }
      }else {
        //show error
        setError({status: true, message: 'Error' + response.status })
      }
    })
    .catch((error) => {
      console.log(error)
      setError({status: true, message: 'Something went wrong'})
    })
  }

  return {saveClaimData, getWarrantyClaimData, getClaimChatsData, addNewChat}
}

export default useClaim
