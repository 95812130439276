import {Link, Navigate} from 'react-router-dom'
import Toast from '../Toast.js'
import Popup from '../Popup.js'
import {useState} from 'react'

function EditProfile({profile, setProfile, updateProfile}){

  let profilePic = profile ? profile.profile_pic : ""

  const [profileInput, setProfileInput] = useState({
    title: profile ? profile.title : 'Mr.',
    name : profile ? profile.name : '',
    email: profile ? profile.email : '',
    mobile: profile ? profile.mobile : '',
    auth_token: profile ? profile.auth_token : ''
  })

  const [error, setError] = useState({status: false, message: ''})
  const [popup, setPopup] = useState({status: false, message: ''})
  const [backPage, setBackpage] = useState(false)

  const handleChange = (e) => {
    setProfileInput({...profileInput,
      [e.target.name] : e.target.value
    })
  }

  const saveProfile = (e) => {
    e.preventDefault()

    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(profileInput.email)){
      setError({...error, status: true, message: 'Invalid email address'})
    }else{
      updateProfile(profileInput, profile, setProfile, setError, setPopup)
    }
    //console.log(profileInput)
  }

  const hidePopup = () => {
    setPopup({status: false})
    setBackpage(true)
  }

  if (!backPage) {
    return(<>
      <div className="blc_bg pda_blc">
          {error.status ? <Toast message ={error.message} hideToast = {() => setError(false)} /> : ''}
          {popup.status ? <Popup show = {popup.status} message ={popup.message} hidePopup = {() => hidePopup()} /> : ''}
                  <div className="container">
                      <div className="back_heading">
                          <Link to = "/web/customer/home/profile">
                             <img src="/images/share-arrow.svg" className="mr-2" alt="Profile Details" />Profile Details
                          </Link>

                      </div>
                      <div className="pdnow">
                          <div className="profile_menu clearfix">
                              <div className="profile_menu_img">
                                  <div className="img_crl">
                                      <img src={profilePic ? profilePic : "/images/user_p.png"} alt="" />
                                  </div>
                              </div>

                              <div className="profile_menu_text">
                                  <span>Welcome!</span>
                                  <h2>{profile ? profile.name : 'User'}</h2>

                              </div>
                          </div>

                      </div>
                  </div>
      </div>

      <div className="container">
              <div className="boxpd toppd fixed_btm">
                  <div className="activeform">


                      <div className="active_in">

                           <div className="position-relative input_ds WD1">
                              <select className="inputbox inht dropd"
                                name = "title" value = {profileInput.title} onChange = {handleChange}>
                                  <option value ="Mr.">MR.</option>
                                  <option value ="Ms.">MS.</option>
                                  <option value ="Mrs.">MRS.</option>
                              </select>
                              <i className="fa fa-angle-down select-arrow"></i>
                            </div>

                          <div className="position-relative input_ds">

                              <input type="text"
                               name = "name"
                               value={profileInput.name}
                               onChange = {handleChange}
                               placeholder = "Name"
                               className="inputbox inht" />
                          </div>

                          <div className="position-relative input_ds">

                              <input type="email"
                              name = "email"
                              value={profileInput.email}
                              onChange = {handleChange}
                              placeholder = "Email"
                              className="inputbox inht"
                              required />
                          </div>

                          <div className="position-relative input_ds">

                              <input type="tel"
                              name = "mobile"
                              value={profileInput.mobile}
                              onChange = {handleChange}
                              placeholder = "mobile No."
                              className="inputbox inht"
                              disabled = {true} />
                          </div>

                      </div>
                  </div>
              </div>
          </div>

          <div className="btn_fixed">
              <button type="submit" className="act" onClick = {saveProfile}><img src="/images/send.svg" alt="Submit"
              className="mr-2" />Save Details </button>
          </div>

    </>)
  }else{
    return <Navigate to ="/web/customer/home/profile" replace />
  }


}

export default EditProfile
