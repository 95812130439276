import {Link} from 'react-router-dom'
import {useState, useContext} from 'react'
import useLogin from '../../hooks/useLogin.js'

function NavbarHome({profile, setAuth, logout}) {

  let profilePic = profile ? profile.profile_pic : ""

  const [nav, setNav] = useState(false)

  return(
    <header className="clearfix headbx">
      <div className="container">
          <div className="smlogo">
              <Link to ="/web/customer/home">
                <img src="/images/vega-logo-w.png" alt="Vega" />
              </Link>
          </div>

          <div className="nav_br">
              <div className="d-flex flsx">
                  <Link to ="/web/customer/home">
                    <img src="/images/home.png" />
                  </Link>

                  <div id="nav-icon3" className={nav ? "open" : ''} onClick = {() => setNav(!nav)}>
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                  </div>
              </div>

              <div className={nav ? "navigation_bx shownv" : "navigation_bx"}>
                  <div className="pd_menu">
                      <div className="profile_menu clearfix">
                          <div className="profile_menu_img">
                              <div className="img_crl">
                                  <img src={profilePic ? profilePic : "/images/user_p.png"} alt="" />
                              </div>
                          </div>

                          <div className="profile_menu_text">
                              <h2>{profile ? profile.name : ''}</h2>
                              <a href="tel:9999999999" className="cnt_txt">{profile ? profile.mobile : ''}</a>
                              <Link to = "/web/customer/home/profile" className="protxt" onClick = {() => setNav(!nav)}>
                                PROFILE
                              </Link>

                          </div>
                      </div>
                      <div className="pdmus">
                          <div className="menu_txt">
                              <ul className="clearfix">

                                  <li>
                                      <Link to = "/web/customer/home/warranty-list" onClick = {() => setNav(!nav)}>
                                        <span className="mu_icon"><img src="/images/WarrantyList.svg" alt="Warranty List" /></span>
                                        Warranty List<span className="img_sv_crl"><img src="/images/arrowcrl.svg" alt="" /></span>
                                      </Link>

                                  </li>

                                  {/* // <li>
                                  //
                                  //     <Link to = "/web/customer/home/privacy" onClick = {() => setNav(!nav)}>
                                  //       <span className="mu_icon"><img src="/images/policy.svg" alt="Privicy policy" /></span>
                                  //       Privacy Policy<span className="img_sv_crl"><img src="/images/arrowcrl.svg" alt="" /></span>
                                  //     </Link>
                                  //
                                  // </li>
                                  //
                                  // <li>
                                  //
                                  //     <Link to = "/web/customer/home/terms" onClick = {() => setNav(!nav)}>
                                  //       <span className="mu_icon"><img src="/images/term.svg" alt="terms & conditions" /></span>
                                  //       Terms & Conditions<span className="img_sv_crl"><img src="/images/arrowcrl.svg" alt="" /></span>
                                  //     </Link>
                                  //
                                  // </li> */}

                              </ul>
                          </div>

                          <div className="logoutbx">
                              <button type="submit" className="logbtn" onClick = {() => logout(setAuth)}><img src="/images/logout.svg" alt="Logout"
                              className="mr-2" />Logout</button>
                          </div>
                      </div>
                  </div>

              </div>
          </div>
      </div>
    </header>
  )
}

export default NavbarHome
